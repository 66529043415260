import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../layouts';
import * as showdown from 'showdown';
import Card from '@material-ui/core/Card';
import styled from '@emotion/styled';
import { Globals, Breakpoints } from '../styles/global';
import { Inner, WhiteBox, outer } from '../components/shared';
import { Tab, Tabs, Box } from '@material-ui/core';
import SEO from '../components/seo';

const converter = new showdown.Converter();

const Article = styled.article`
  a {
    text-decoration: none;

    &:hover > div {
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.3),
        0px 1px 1px 0px rgba(0, 0, 0, 0.34), 0px 1px 3px 0px rgba(0, 0, 0, 0.22);
    }
  }
`;

const EventArticle = styled.article`
  a {
    text-decoration: none;
    color: ${Globals.grayscale_u2};

    &:hover > div {
      text-decoration: underline;
    }
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box mb={4}>{children}</Box>}
    </div>
  );
}

const tabProps = (index: any) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    style: {
      opacity: 1,
    },
  };
};

const EventRow = ({
  date = new Date().toString(),
  displayTitle = '',
  excerpt = '',
  slug = '/',
}) => {
  const formatDate = new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(new Date(date));
  return (
    <EventArticle>
      <Link to={slug}>
        <div className="px-b mb-d2">
          <div className="space-between align-end">
            <h3 className="font-b pt-b mt-d2 pb-0 mb-0 mr-b">{displayTitle}</h3>
          </div>
          <div className="font-d1">{formatDate}</div>
        </div>
      </Link>
    </EventArticle>
  );
};

const NewsRow = ({
  date = new Date().toString(),
  displayTitle = '',
  excerpt = '',
  slug = '/',
}) => {
  const formatDate = new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(new Date(date));
  return (
    <Article>
      <Link to={slug}>
        <Card className="px-b pb-b mb-b">
          <div className="space-between align-end">
            <h3 className="font-u1 pt-b mt-d2 pb-d2 mb-d2">{displayTitle}</h3>
            <span className="font-d1 pt-b mt-d2 pb-d2 mb-d2">{formatDate}</span>
          </div>
          <div>{excerpt}</div>
        </Card>
      </Link>
    </Article>
  );
};

type Category =
  | 'all'
  | 'system-of-care'
  | 'transition-youth'
  | 'adopting'
  | 'fostering';
interface IPost {
  node: {
    fields: {
      slug: string;
    };
    frontmatter: {
      title: string;
      displayTitle: string;
      postType: 'news' | 'events';
      category: Category;
      excerpt: string;
      date: string;
      file: string;
    };
  };
}

const TopLinks = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  a {
    display: flex;
    flex-wrap: wrap;
    text-decoration: none;
    min-height: 14rem;
    color: ${Globals.white};
    padding: 2rem;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 60%;
    margin-bottom: 2rem;
    &:hover,
    &.active,
    &[aria-current='page'] {
      box-shadow: 0 8px 20px 10px rgba(0, 0, 0, 0.1),
        inset 0px 0px 10px 20px rgba(255, 255, 255, 0.1);
    }
  }

  h1,
  p {
    width: 100%;
  }

  .adopt {
    background-color: ${Globals.yellow};
    background-image: url('/yellow-news-background.png');
    background-position: center right;
    background-size: 65%;
  }

  .ty {
    background-color: ${Globals.green};
    background-image: url('/green-news-background.png');
    background-position: bottom right;
    background-size: 60%;
  }

  .sc {
    background-color: ${Globals.orange};
    background-image: url('/orange-news-background.png');
    background-position: center right;
    background-size: 133%;
  }

  .foster {
    background-color: ${Globals.purple};
    background-image: url('/purple-news-background.png');
  }
  ${Breakpoints.sm`
    a {
      width: calc(50% - 1rem);
    }
    .adopt {
      background-size: 60%;
    }
  `}
`;

const colorMap: { [K in Category]: string } = {
  fostering: Globals.purple,
  adopting: Globals.yellow,
  all: Globals.white,
  'system-of-care': Globals.orange,
  'transition-youth': Globals.green,
};

const Row = styled.div<{ category: Category }>`
  ${outer}

  ${props =>
    props.category
      ? `background-color: ${colorMap[props.category]}`
      : `background-color: ${Globals.white}`};
`;

const Gridded = styled.div`
  ${Breakpoints.sm`
    display: flex;
    justify-content: space-between;
    > section {
      width: calc(50% - 1rem);
    }
  `}
`;

const EventSection = styled.section`
  background-color: ${Globals.white};
`;

export const NewsEventsPageTemplate = ({
  title,
  content,
  category,
  posts,
}: {
  title: string;
  content: any;
  category: Category;
  posts: IPost[];
}) => {
  const filteredPosts: IPost[] =
    category === 'all'
      ? posts
      : posts.filter(post => post.node.frontmatter.category === category);

  const [tabValue, setTab] = React.useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  const today = new Date();

  const upcomingEventPosts = filteredPosts
    .filter(post => {
      const {
        postType = 'events',
        date = new Date().toString(),
      } = post.node.frontmatter;
      return postType === 'events' && new Date(date) > today;
    })
    .sort((a, b) => {
      const { date: aDateString = new Date().toString() } = a.node.frontmatter;
      const { date: bDateString = new Date().toString() } = b.node.frontmatter;
      const aDate = new Date(aDateString);
      const bDate = new Date(bDateString);
      if (aDate < bDate) return -1;
      if (aDate > bDate) return 1;
      return 0;
    });

  const pastEventPosts = filteredPosts
    .filter(post => {
      const {
        postType = 'events',
        date = new Date().toString(),
      } = post.node.frontmatter;
      return postType === 'events' && new Date(date) < today;
    })
    .sort((a, b) => {
      const { date: aDateString = new Date().toString() } = a.node.frontmatter;
      const { date: bDateString = new Date().toString() } = b.node.frontmatter;
      const aDate = new Date(aDateString);
      const bDate = new Date(bDateString);
      if (aDate > bDate) return -1;
      if (aDate < bDate) return 1;
      return 0;
    });

  return (
    <>
      <SEO title={title} />
      <WhiteBox>
        <Inner>
          <TopLinks>
            <Link className="foster" to="/news-events/fostering">
              <h1 className="font-u2">Fostering</h1>
            </Link>
            <Link className="ty" to="/news-events/transition-youth">
              <h1 className="font-u2">
                Transitioning <br />
                Youth
              </h1>
            </Link>
            <Link className="sc" to="/news-events/system-of-care">
              <h1 className="font-u2">System of Care</h1>
            </Link>
            <Link className="adopt" to="/news-events/adopting">
              <h1 className="font-u2">Adopting</h1>
            </Link>
          </TopLinks>
          <h2 className="font-u2">{title}</h2>
          <div
            dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }}
          />
        </Inner>
      </WhiteBox>
      <Row category={category}>
        <Inner>
          <h3 className="font-u1 mb-b">News</h3>
          {posts.length > 0 && (
            <Gridded>
              <section>
                {filteredPosts
                  .filter(post => post.node.frontmatter.postType === 'news')
                  .sort((a, b) => {
                    const {
                      date: aDateString = new Date().toString(),
                    } = a.node.frontmatter;
                    const {
                      date: bDateString = new Date().toString(),
                    } = b.node.frontmatter;
                    const aDate = new Date(aDateString);
                    const bDate = new Date(bDateString);
                    if (aDate > bDate) return -1;
                    if (aDate < bDate) return 1;
                    return 0;
                  })
                  .map((post, index) => {
                    const {
                      node: {
                        frontmatter: { excerpt, date, displayTitle: postTitle },
                        fields: { slug },
                      },
                    } = post;

                    return (
                      <NewsRow
                        key={index}
                        excerpt={excerpt}
                        displayTitle={postTitle}
                        date={date}
                        slug={slug}
                      />
                    );
                  })}
              </section>
              <EventSection>
                <Card>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label={`Steps to`}
                    variant="fullWidth"
                  >
                    <Tab label="Upcoming Events" {...tabProps(0)} />
                    <Tab label="Past Events" {...tabProps(1)} />
                  </Tabs>

                  <TabPanel value={tabValue} index={0}>
                    {upcomingEventPosts.map((post, index) => {
                      const {
                        node: {
                          frontmatter: { excerpt, date, displayTitle },
                          fields: { slug },
                        },
                      } = post;

                      return (
                        <EventRow
                          key={index}
                          excerpt={excerpt}
                          displayTitle={displayTitle}
                          date={date}
                          slug={slug}
                        />
                      );
                    })}
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    {pastEventPosts.map((post, index) => {
                      const {
                        node: {
                          frontmatter: { excerpt, date, displayTitle },
                          fields: { slug },
                        },
                      } = post;

                      return (
                        <EventRow
                          key={index}
                          excerpt={excerpt}
                          displayTitle={displayTitle}
                          date={date}
                          slug={slug}
                        />
                      );
                    })}
                  </TabPanel>
                </Card>
              </EventSection>
            </Gridded>
          )}
        </Inner>
      </Row>
    </>
  );
};

const NewsEventsPage = ({
  data: {
    markdownRemark: {
      frontmatter: { title = '', content = '', category = 'all' },
    },
    allMarkdownRemark: { edges: posts },
  },
}) => {
  return (
    <Layout>
      <NewsEventsPageTemplate
        title={title}
        content={content}
        category={category as Category}
        posts={posts as IPost[]}
      />
    </Layout>
  );
};

export default NewsEventsPage;

export const newsEventsPageQuery = graphql`
  query NewsEventsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      htmlAst
      frontmatter {
        title
        content
        category
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "news-page" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            category
            title
            displayTitle
            postType
            excerpt
            date
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
